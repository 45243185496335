header {
    z-index: 9999;
}

.main-menu {
    width: 100%;
    position: relative;
    padding: 18px 0;
}

.main-menu .main-menu__block {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.main-menu .main-menu__left {
    display: flex;
    align-items: center;
    gap: 84px;
}

.main-menu .main-menu__left .main-menu__logo {
    align-items: center;
    position: relative;
    width: 130px;
    z-index: 2;
}

.main-menu .main-menu__left .main-menu__nav {
    margin-left: auto;
    margin-right: auto;
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list,
.main-menu .main-menu__left .main-menu__nav .main-menu__list ul,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    display: none;
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list>li,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li {
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
    margin: 0px 10px;
    z-index: 2;
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list>li.current,
.main-menu .main-menu__left .main-menu__nav .main-menu__list>li:hover,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li.current,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li:hover {
    color: var(--primary-color);
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list>li>a,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li>a {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    text-align: center;
    flex-direction: column;
    gap: 5px;
    padding: 4px 14px;
    color: #212627;
    font-weight: 600;
    line-height: 130%;
    text-transform: capitalize;
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease;
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list>li>a svg,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li>a svg {
    font-size: 20px;
}

.main-menu .main-menu__left .main-menu__nav .main-menu__list>li>a.active,
.main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list>li>a.active {
    color: #ffa85d !important;
}

@media screen and (min-width: 1200px) {

    .main-menu .main-menu__left .main-menu__nav .main-menu__list,
    .main-menu .main-menu__left .main-menu__nav .main-menu__list ul,
    .main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list,
    .main-menu .main-menu__left .main-menu__nav .stricky-header .main-menu__list ul {
        display: flex;
        gap: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .main-menu_rig {
        position: relative;
        z-index: 2;
    }

    .main-menu .main-menu__left {
        gap: 0;
    }
}


.main-menu__list a.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    /* Position below the text */
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--primary-color);
    /* Underline color */
    transition: width 0.3s ease;
    /* Smooth underline transition */
    width: 100%;
    /* Full-width underline */
}



.main-menu_rig {
    display: flex;
    align-items: center;
    gap: 16px;
}

.main-menu__right {
    z-index: 2;
}

.main-menu__right .main-menu__search {
    color: #16191A;
    font-size: 24px;
    text-decoration: none;
    border: none;
    background: none;
}

.main-menu__right .main-menu-signup__login {
    display: flex;
    align-items: center;
    gap: 10px;
}

.wallet-hbtn {
    background: white;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    padding: 6px 20px 2px;
    color: var(--primary-color);
    font-weight: 600;
}

.wallet-hbtn svg {
    font-size: 24px;
}

.main-menu__right .main-menu__login {
    position: relative;
    font-size: 21px;
    font-weight: 500;
    line-height: 130%;
    color: #16191A;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 500ms ease;
}

.main-menu__right .center_slach {
    font-size: 21px;
    font-weight: 500;
    line-height: 130%;
    color: #16191A;
}

.mobile-nav__toggler {
    font-size: 24px;
    font-weight: 300;
    color: #0B0C0D;
    cursor: pointer;
    transition: 500ms;
}

.expanded .main-menu__toggler {
    position: absolute;
    color: #0B0C0D;
    right: 10px;
    top: 30px;
    z-index: 999999;
}

.expanded .main-menu__toggler {
    position: absolute;
    color: #0B0C0D;
    /* right: -50px; */
}

@media screen and (min-width: 1200px) {
    .mobile-nav__toggler {
        display: none;
    }
}