.cstm-form-cntrl,
.cstm-form-cntrl:focus {
    background: rgba(255, 255, 255, 1);
    box-shadow: none;
    border: none !important;
}

.bg-atcst {
    background-color: #fff;
    border: .0625rem solid #e7eaf3;
    border-color: rgba(55, 125, 255, .4);
    width: 100%;
    border-radius: .3125rem;
}

.pt-60 {
    padding-top: 15rem;
}

.pb-4 {
    padding-bottom: 1rem;
}

.rounded-br-\[8rem\] {
    border-bottom-right-radius: 8rem;
}

.gap-10 {
    gap: 2.5rem;
}

/* .\32xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
} */
.decoration-purple-800 {
    text-decoration-color: var(--primary-color);
}

.underline {
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
}

.bg-text-gradient {
    /* background-image: linear-gradient(90deg, #2026a6 15%, #b165fd 91.93%); */
    color: var(--primary-color);
}

/* .bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
} */

/* .cursor-pointer {
    cursor: pointer;
} */


































/* .\32xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
} */