@media (max-width: 575px) {
    footer {
        padding: 26px 10px !important;
    }
}

footer form {
    position: relative;
}

footer form input {
    position: relative;
}

.flogo {
    height: 80px;
    margin-bottom: 10px;
}

.flogo img {
    height: 100%;
}

footer form button {
    background: transparent;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.32px;
    position: absolute;
    top: 16px;
    right: 16px;
}

footer form button::before {
    content: "";
    height: 24px;
    width: 1px;
    background: #16191A;
    position: absolute;
    left: -8px;
}

footer .social-link ul {
    display: flex;
    gap: 16px;
}

footer .social-link ul li a {
    background-color: var(--primary-color);
    padding: 6px;
    border-radius: 10px;
    height: 30px;
    width: 30px;
}

footer .social-link ul li:hover {
    background-color: var(--primary-color);
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}