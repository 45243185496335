.dropdown-container-sit {
    position: relative;
}

.dropdown-container-sit .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 80%);
    /* border: 1px solid #ccc; */
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;
    border-radius: 4px;
    margin: 0;
    width: 264%;
}


.dropdown-container-sit svg {
    margin-right: 6px;
    margin-bottom: 2px;
}

.dropdown-container-sit .dropdown-item {
    padding: 8px;
    cursor: pointer;
}

.dropdown-container-sit .dropdown-item.active {
    background-color: #f0f0f0;
}

.dropdown-container-sit .dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-container-sit .input-error {
    border-bottom: 2px solid red;
}

.dropdown-container-sit .error-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}