/* Slider */
.slick-loading .slick-list {
    background: #fff url('../fonts/ajax-loader.gif') center center no-repeat;
}

/* Icons */

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    color: #7A7F85;
    position: absolute;
    top: -66px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 999;
    cursor: pointer;

    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 30px;
    font-weight: 200;
    line-height: 1;
    width: 47px;
    padding: 4px 16px;
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03), 53px 27px 35px 0px rgba(0, 0, 0, 0.02), 94px 48px 42px 0px rgba(0, 0, 0, 0.01);
    ;
    opacity: 1;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.slick-prev:hover,
.slick-next:hover {
    background-color: #ffffff;
    color: #020202;
}

.slick-prev {
    right: 90px;
}

[dir='rtl'] .slick-prev {
    right: 23px;
    left: auto;
}

.slick-prev:before {
    content: "\f104";
}

[dir='rtl'] .slick-prev:before {
    content: 'â†’';
}

.slick-next {
    right: 38px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: "\f105";
}

[dir='rtl'] .slick-next:before {
    content: 'â†';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: 'â€¢';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

@media only screen and (max-width: 1199px) {
    .slick-next {
        top: -60px;
    }

    .slick-prev {
        top: -60px;
    }
}

@media only screen and (max-width: 850px) {
    .slick-next {
        top: -48px;
    }

    .slick-prev {
        top: -48px;
    }
}