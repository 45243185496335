.benefit {
    position: relative;
}

.benefit .benefit-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 6px 3px 14px 0px rgba(0, 0, 0, 0.04), 23px 12px 26px 0px rgba(0, 0, 0, 0.03), 53px 27px 35px 0px rgba(0, 0, 0, 0.02), 94px 48px 42px 0px rgba(0, 0, 0, 0.01);
    padding: 12px;
    border-radius: 10px;
}

.benefit .benefit-block .image-box img {
    width: 36px;
    filter: invert(41%) sepia(40%) saturate(6780%) hue-rotate(423deg) brightness(142%) contrast(94%) !important
}