#preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
    text-align: center;
    background: #F8F8FF;
    z-index: 9999999;
    align-content: center;
}

#preloader .loader {
    text-align: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    max-width: 35rem;
    height: 18rem;
    margin: 0 auto;
}

#preloader .loader .wait {
    margin: 5rem 0;
    visibility: hidden;
}

#preloader .loader .iata_code {
    font-size: 4rem;
    font-weight: 600;
    top: 60%;
    position: absolute;
    color: var(--primary-color);
}

#preloader .loader .iata_code.departure_city {
    left: 55px;
}

#preloader .loader .iata_code.arrival_city {
    right: 0.5rem;
}

#preloader .loader .plane {
    position: absolute;
    margin: 0 auto;
    width: 100%;
}

#preloader .loader .plane .plane-img {
    -webkit-animation: spin 2.5s linear infinite;
    -moz-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
}

@media (max-width: 575px) {
    #preloader .loader .plane .plane-img {
        width: 210px;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#preloader .loader .earth-wrapper {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    padding-top: 2.7rem;
}

#preloader .loader .earth-wrapper .earth {
    width: 160px;
    height: 160px;
    background: url("https://zupimages.net/up/19/34/6vlb.gif");
    border-radius: 100%;
    background-size: 340px;
    animation: earthAnim 12s infinite linear;
    margin: 0 auto;
    border: 1px solid #CDD1D3;
}

@media (max-width: 575px) {
    #preloader .loader .earth-wrapper .earth {
        width: 120px;
        height: 120px;
    }
}

@keyframes earthAnim {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -340px;
    }
}

@media screen and (max-width: 420px) {
    #preloader .loader .departure_city {
        left: 0;
        right: 0;
        top: 30%;
        position: absolute;
        margin: 0 auto;
    }

    #preloader .loader .arrival_city {
        left: 0;
        right: 0;
        top: 93%;
        position: absolute;
        margin: 0 auto;
    }
}