.blog-video-sec .video-block {
    position: relative;
    padding: 24px 24px 0 24px;
}

.blog-video-sec .video-block .video-btn {
    position: absolute;
    top: 61%;
    left: 48%;
}

@media (max-width: 1099px) {
    .blog-video-sec .video-block .video-btn {
        top: 65%;
        left: 47%;
    }
}

@media (max-width: 836px) {
    .blog-video-sec .video-block .video-btn {
        top: 69%;
        left: 46%;
    }
}

@media (max-width: 609px) {
    .blog-video-sec .video-block .video-btn {
        top: 73%;
        left: 47%;
        width: 8%;
    }
}

@media (max-width: 480px) {
    .blog-video-sec .video-block .video-btn {
        top: 78%;
        left: 46%;
    }
}

@media (max-width: 430px) {
    .blog-video-sec .video-block .video-btn {
        top: 80%;
    }
}

@media (max-width: 373px) {
    .blog-video-sec .video-block .video-btn {
        top: 83%;
    }
}