.search-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    -webkit-transition: all 1s ease;
    -khtml-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    display: none;
}

.search-popup.active {
    display: block;
}

.search-popup .search-popup__overlay {
    position: fixed;
    width: 224vw;
    height: 224vw;
    top: calc(90px - 112vw);
    right: calc(50% - 112vw);
    z-index: 3;
    display: block;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -khtml-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: transform 0.8s ease-in-out;
    -khtml-transition: transform 0.8s ease-in-out;
    -moz-transition: transform 0.8s ease-in-out;
    -ms-transition: transform 0.8s ease-in-out;
    -o-transition: transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;
    transition-delay: 0s;
    transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
    background-color: #363636;
    opacity: 0.8;
    /* cursor: url(https://flight.readytouse.in/assets/media/close.png), auto; */
}

@media (max-width: 767px) {
    .search-popup .search-popup__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transform: translateY(-110%);
    }
}

.search-popup .search-popup__content {
    position: fixed;
    width: 0;
    max-width: 560px;
    padding: 30px 15px;
    left: 50%;
    top: 50%;
    opacity: 0;
    z-index: 3;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    -khtml-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    -moz-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    -ms-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    -o-transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    transition: opacity 0.5s 0s, width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
    transition-delay: 0s, 0.8s, 0s;
    transition-delay: 0s, 0.4s, 0s;
    transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
}

.search-popup .search-popup__content .search-popup__form {
    position: relative;
}

.search-popup .search-popup__content .search-popup__form input[type=search],
.search-popup .search-popup__content .search-popup__form input[type=text] {
    width: 100%;
    background-color: #ffffff !important;
    font-size: 15px;
    color: #697585;
    border: none;
    outline: none;
    height: 66px;
    padding-left: 30px;
}

.search-popup .search-popup__content .search-popup__form button {
    padding: 0;
    width: 66px;
    height: 66px;
    border: 0;
    background: var(--primary-color);
    color: #F8F8FF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: -1px;
    border-radius: 0;
}

.search-popup .search-popup__content .search-popup__form button i {
    margin: 0;
    font-size: 24px;
}

.search-popup .search-popup__content .search-popup__form button::after {
    background-color: #9b59b6;
}

.search-popup.active {
    z-index: 9999;
}

.search-popup.active .search-popup__overlay {
    top: auto;
    bottom: calc(90px - 112vw);
    -webkit-transform: scale(1);
    -khtml-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    opacity: 0.8;
    -webkit-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -khtml-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -moz-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -ms-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -o-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
}

@media (max-width: 767px) {
    .search-popup.active .search-popup__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transform: translateY(0%);
    }
}

.search-popup.active .search-popup__content {
    width: 100%;
    opacity: 1;
    transition-delay: 0.7s;
    -webkit-transition-delay: 0.7s;
}

.close-pop {
    /* background-image: url(https://flight.readytouse.in/assets/media/close.png); */
    z-index: 99999;
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 40px;
    /* border-radius: 50%; */
    overflow: hidden;
    /* background: red;
    color: aliceblue; */
}

.close-pop i {
    color: var(--primary-color);
    /* background-color: black; */
}