.vtt-type-icon {
    font-size: 1.125rem;
    flex-shrink: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #e5e9f2;
    margin-right: 1rem;
}

.bg-success-dim {
    background-color: #e0f8ee !important;
}

.bg-danger-dim {
    background-color: #f9e0e3 !important;
}

.bg-warning-dim {
    background-color: #f9f3e1 !important;
}

.rotate-45 {
    transform: rotate(45deg);
}

.badge-dot:before {
    position: absolute;
    content: "";
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    height: 6px;
    width: 6px;
    margin-top: 10px;
    border-radius: 50%;
    background: currentColor;
}

.badge-dot.bg-success {
    color: #0fca7a;
}

/* .badge.bg-success {
    border-color: #0fca7a;
    background: #0fca7a;
} */

.badge-dot {
    display: inline-flex;
    align-items: center;
    background-color: transparent !important;
    border: none;
    padding-left: 12px;
    padding-right: 0;
    font-size: 12px;
}

.table>:not(caption)>*>* {
    position: relative;
}



/* Wallet Popup CSS Start */

#tranxDetails.modal {
    background: #f5f6fadb;
}

#tranxDetails.modal .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

#tranxDetails.modal .modal-content {
    position: relative;
    min-height: 40px;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgba(82, 100, 132, .2), 0 8px 15.52px .48px rgba(28, 43, 70, .15);
}

#tranxDetails.modal .modal-content .modal-body {
    background-color: #f5f6fa;
    border-radius: 10px;
}

#tranxDetails .close {
    float: right;
    font-size: 1.505rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    border: none;
    background: none;
}

#tranxDetails .modal-content>.close {
    position: absolute;
    top: .75rem;
    right: .75rem;
    height: 2.25rem;
    width: 2.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #526484;
    z-index: 1;
    transition: all .3s;
}

#tranxDetails.modal .modal-body-md {
    padding: 1.75rem 1.25rem;
}

.nk-modal-title.title {
    font-size: 1.5rem;
}

.nk-modal-title.title .text-primary {
    color: #ffa85d !important;
}

.nk-block-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nk-tnx-type {
    display: flex;
    align-items: center;
}

.nk-tnx-type-icon {
    font-size: 1.125rem;
    flex-shrink: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #e5e9f2;
    margin-right: 1rem;
}

.sub-text {
    display: block;
    font-size: 13px;
    color: #8094ae;
}

.nk-tnx-details ul li::marker {
    content: "";
}

.badge-sm {
    padding: .3125rem .75rem;
    font-size: .75rem;
}

#tranxDetails.modal .badge {
    position: relative;
    border: 1px solid currentColor;
    line-height: 1.125rem;
    font-family: Roboto, sans-serif;
    letter-spacing: .01em;
    vertical-align: middle;
    display: inline-flex;
}











@media (min-width: 576px) {
    /* #tranxDetails.modal {
        margin: 1.75rem;
        box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2), 0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
    } */

    #tranxDetails .modal-dialog {
        min-height: calc(100% - 3.5rem);
    }

    #tranxDetails.modal .modal-body {
        padding: 1.5rem 1.5rem;
    }
}

@media (min-width: 576px) {
    #tranxDetails.modal .modal-body-md {
        padding: 2.25rem 2.5rem;
    }
}










/* Wallet Popup CSS End */