.title-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url(../../assets/images/backgrounds/title-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.title-banner .banner-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 32px 16px;
}

.flight-listing .title-banner .banner-area.v-2 {
    padding: 32px 16px 275px;
}

.title-banner .banner-area .left-image {
    width: 17%;
}

.title-banner .banner-area .content-box {
    text-align: center;
    padding-left: 6%;
}

@media (max-width: 575px) {
    .title-banner .banner-area .content-box h1 {
        font-size: 26px;
    }
}

.title-banner .banner-area .content-box .title-break {
    display: none;
}

@media (max-width: 899px) {
    .title-banner .banner-area .content-box .title-break {
        display: block;
    }
}

@media (max-width: 575px) {
    .title-banner .banner-area .content-box .title-break {
        display: none;
    }
}

.title-banner .banner-area .right-image {
    width: 29%;
}

.title-banner .banner-area .right-image.tour-image {
    mix-blend-mode: darken;
}